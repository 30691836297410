






































































































































































































































































































    .el-breadcrumb {
        margin-bottom: 15px;
        // font-size: 12px;
    }


    .icon-exchangerate {
        font-size: 30px;
        float: right;
        text-align: center;
    }

    .icon-exchangerate:hover {
        font-size: 32px;
        // color: #00FFFF;
    }

    .demo-table-expand {
        font-size: 0;
    }

    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }

    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 100%;
    }
	
	.tag {
		position: relative;
		top: -1px;
		display: inline-block;
		width: 6px;
		height: 6px;
		vertical-align: middle;
		border-radius: 50%;
		margin-right: 6px;
	}
	.el-breadcrumb {
		margin-bottom: 15px;
		// font-size: 12px;
	}
	
	
	.icon-exchangerate {
		font-size: 32px;
		float: right;
		text-align: center;
	}
	
	.icon-exchangerate:hover {
		color: #1890FF;
	}
